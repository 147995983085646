.section-hero-img-right {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 270px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-hero-img-right-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: 100%;
  max-width: 1200px;
  clear: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-background {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-image: url("../../assets/images/wedding.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 0;
  transition: all 400ms;
}

.wrapper-text {
  position: relative;
  left: -10%;
  top: 10px;
  right: 0px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: auto;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: transparent;
  cursor: default;
  -webkit-transform: translate3d(-315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(-315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(-315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(-315px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 0;
  transition: all 800ms;
}

.heading {
  width: 90%;
  margin-top: 10px;
  border-bottom: 2px solid #000;
  color: #000;
  font-size: 55px;
  line-height: 1.3;
  text-transform: none;
}

.paragraph {
  margin-top: 10px;
  color: #000;
  font-size: 20px;
  line-height: 1.5;
}

.section-hero-img-right[data-page-load="init"] .wrapper-background {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

.section-hero-img-right[data-page-load="init"] .wrapper-text {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .section-hero-img-right {
    float: none;
    clear: none;
  }

  .section-hero-img-right-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .heading {
    width: 100%;
  }

  .paragraph {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

@media screen and (max-width: 767px) {
  .section-hero-img-right {
    margin-bottom: 10px;
  }

  .section-hero-img-right-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wrapper-text {
    left: 0%;
    top: 28px;
    width: 100%;
    clear: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .heading {
    border: none;
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }

  .wrapper-background {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 300px;
    opacity: 0.5;
  }
}

@media screen and (max-width: 479px) {
  .section-hero-img-right {
    padding: 100px 20px;
  }

  .section-hero-img-right-content {
    width: 100%;
  }

  .wrapper-background {
    top: -180%;
  }

  .wrapper-text {
    top: 10px;
    margin-top: 20px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading {
    margin-top: 0px;
  }

  .paragraph {
    text-align: center;
    font-size: 18px;
  }
}
