.post {
  display: flex;
  height: auto;
  padding-top: 100px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section-hero-blog-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-image-post {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 50%;
  height: 100vh;
  max-height: 100vh;
  // background-image: url("../../assets/images/blog/post-1.jpg");
  background-position: 0px 0px;
  background-size: cover;
  padding: 100px 50px 50px 50px;
}

.wrapper-text-blog-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 100px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-blog-post {
  width: auto;
  max-width: 80%;
}

.heading-blog-post {
  font-size: 36px;
}

.text-date {
  margin-bottom: 40px;
  font-size: 18px;
}

.w-richtext {
  word-break: break-word;
}

@media screen and (max-width: 991px) {
  .section-hero-blog-post {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrapper-image-post {
    position: static;
    width: 100%;
    max-height: 60vh;
  }

  .wrapper-text-blog-post {
    min-width: 90%;
  }

  .content-blog-post {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .section-hero-blog-post {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrapper-image-post {
    position: static;
    width: 100%;
    height: 600px;
    margin-top: 0px;
  }

  .wrapper-text-blog-post {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 479px) {
  .wrapper-image-post {
    height: 60vh;
    background-position: 100% 50%;
  }

  .wrapper-text-blog-post {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .content-blog-post {
    max-width: 100%;
  }
}
