.hero-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  background-image: url("../../assets/images/bruce-mars.jpg");
  background-position: 50% 100%;
  background-size: cover;
  opacity: 1;
}

.section-hover {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;

  .wrapper-options {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
}

.section-hover:hover {
  background-color: rgba(0, 0, 0, 0.3);

  .wrapper-options {
    opacity: 1;
  }
}

.wrapper-options {
  position: static;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
}

.option {
  margin-right: 10px;
  margin-left: 10px;
  padding: 3px 5px;
  border-top: 1px solid #fff;
  -webkit-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
  text-decoration: none;
}

.option:hover {
  color: #ccbba6;
}

.name-options {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
  font-family: "ZCOOL XiaoWei", sans-serif;
  color: #fff;
  font-weight: 400;
}

.name-options:hover {
  color: #ccbba6;
}

@media screen and (max-width: 991px) {
  .hero-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-hover {
    width: 100%;
    height: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wrapper-options {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .hero-section {
    height: 100vh;
  }

  .section-hover {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.68);
  }

  .section-hover:hover {
    background-color: rgba(0, 0, 0, 0.68);
  }

  .wrapper-options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .option {
    border: 1px none #000;
  }
}

@media screen and (max-width: 479px) {
  .hero-section {
    height: 100vh;
    padding-bottom: 0px;
  }

  .section-hover {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .wrapper-options {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .option {
    padding-bottom: 20px;
    text-align: center;
  }
}
