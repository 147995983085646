.blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-heading {
  color: #000;
}

.paragraph {
  color: #000;
  text-align: center;
}

.section-blog-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collection-list-wrapper-blog {
  width: 100%;
  max-width: 1200px;
}

.grid-collection-list {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.collection-item-blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.block-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);

  &:hover {
    box-shadow: 0 2px 10px rgb(0 0 0 / 60%);
  }
}

.wrapper-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 450px;
  max-height: none;
  max-width: 350px;
  min-height: 300px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.wraooer-color {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.65);
  color: rgba(51, 51, 51, 0.65);
}

.wrapper-image-blog-post {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 60%;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 0px 0px;
  background-size: cover;

  // &.img-1 {
  //   background-image: url("../../assets/images/blog/blog-1-500.jpeg");
  // }
}

.wrapper-yexy-card-blog {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px 20px 30px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  color: transparent;
}

.heading-card-blog {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333;
  font-size: 20px;
  line-height: 1.5;
}

.date-blog-post {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #333;
  font-size: 15px;
}

@media screen and (max-width: 991px) {
  .section-blog-card {
    margin-right: 0px;
    padding-right: 60px;
    padding-left: 60px;
  }

  .grid-collection-list-product {
    width: 100%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .grid-collection-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .collection-item-blog {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wrapper-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .grid-collection-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .collection-item-blog {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wrapper-card {
    width: 300px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .heading-card-blog {
    font-size: 17px;
  }
}

@media screen and (max-width: 479px) {
  .shop-heading {
    font-size: 50px;
    text-align: center;
  }

  .section-blog-card {
    padding: 60px 25px;
  }

  .heading-blog-page {
    text-align: center;
  }

  .collection-item-blog {
    padding-right: 0px;
    padding-left: 0px;
  }

  .wrapper-card {
    width: 300px;
  }

  .wrapper-yexy-card-blog {
    height: 40%;
  }

  .heading-card-blog {
    font-size: 17px;
  }
}
