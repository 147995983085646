.footer-black {
  position: relative;
  bottom: 0px;
  padding: 20px 100px 25px;
  background-color: #181818;
  color: #181818;
}

.wrapper-footer-black {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.copyright-footer-black {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 11px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.wrapper-link-social-black {
  margin-left: 15px;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .footer-black {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .wrapper-footer-black {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .social {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .copyright-footer-black {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .social {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}
