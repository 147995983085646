.footer {
  position: static;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 100px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.content-sub-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e2ddd7;
}

.container-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.link-brand-footer {
  text-decoration: none;
  font-size: 28px;
  max-width: 100%;
  display: inline-block;
}

.copyright {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "ZCOOL XiaoWei", sans-serif;
  color: #696969;
  font-size: 11px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.content-voci-menu-footer {
  width: 65%;
  margin-top: 40px;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.grid-menu-footer {
  width: 100%;
  justify-items: stretch;
  -ms-grid-columns: 0.65fr 0.65fr 0.65fr 1.25fr;
  grid-template-columns: 0.65fr 0.65fr 0.65fr 1.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.list-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.heading-footer {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  color: #000;
  font-size: 21px;
}

.wrapper-link-footer {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.link-footer {
  text-decoration: none;
}

.link-footer:hover {
  color: #b37728;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.image-social {
  -webkit-transition: -webkit-transform 400ms ease-in-out;
  transition: -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  cursor: pointer;
}

.image-social:hover {
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.heading-newsletter-footer {
  margin-top: 10px;
  font-size: 21px;
}

.paragraph-newsletter-footer {
  width: 100%;
  font-size: 16px;
}

.heading-newsletter-footer {
  margin-top: 10px;
  font-size: 21px;
}

.w-form {
  margin: 0 0 15px;
}
.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}

.text-field {
  height: auto;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  border: 1px none #000;
  background-color: transparent;
}

.success-message {
  background-color: #ccbba6;
  color: #fff;
  font-size: 16px;
}

.text-block-15 {
  font-family: "ZCOOL XiaoWei", sans-serif;
  font-size: 16px;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.submit-button {
  padding-top: 15px;
  padding-right: 10px;
  padding-bottom: 15px;
  border-bottom: 1px none #000;
  background-color: transparent;
  color: #000;
}

.newsletter-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-bottom: 2px solid #e2ddd7;
}

input.w-button {
  -webkit-appearance: button;
}

.submit-button:hover {
  background-color: transparent;
  color: #000;
}

@media screen and (max-width: 991px) {
  .content-sub-menu {
    width: 90%;
  }

  .content-voci-menu-footer {
    width: 90%;
  }

  .grid-menu-footer {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .content-sub-menu {
    width: 80%;
  }

  .copyright {
    text-align: center;
  }

  .grid-menu-footer {
    width: auto;
    grid-auto-flow: row;
    grid-column-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
}

@media screen and (max-width: 479px) {
  .footer {
    padding-right: 10px;
    padding-left: 10px;
  }

  .content-sub-menu {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .grid-menu-footer {
    width: 100%;
  }

  .list-footer {
    margin-right: 9px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .wrapper-link-footer {
    text-align: left;
  }

  .link-footer {
    text-align: left;
  }
}
