.section-hero-img-left {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 270px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.section-hero-img-left-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.wrapper-text {
  position: relative;
  left: 8%;
  top: 10px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: transparent;
  cursor: default;
  -webkit-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 0;
  transition: all 800ms;
}

.heading {
  width: 95%;
  margin-top: 10px;
  padding-right: 0px;
  border-style: none none solid;
  border-width: 1px 1px 2px;
  border-color: #606060 #606060 #000;
  color: #000;
  font-size: 55px;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
}

.paragraph {
  margin-top: 10px;
  color: #000;
  font-size: 20px;
  line-height: 1.5;
}

.wrapper-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/images/background.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 0;
  transition: all 400ms;
}

.section-hero-img-left[data-page-load="init"] .wrapper-background {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

.section-hero-img-left[data-page-load="init"] .wrapper-text {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .wrapper-text {
    position: relative;
    left: 10%;
    top: 60px;
    bottom: 0px;
    width: 55%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .paragraph {
    height: 100%;
    text-align: center;
  }

  .wrapper-background {
    right: -5%;
  }
}

@media screen and (max-width: 767px) {
  .section-hero-img-left {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-hero-img-left-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .wrapper-text {
    left: 0;
    width: 80%;
    margin-bottom: 40px;
    padding-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .heading {
    border: none;
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }

  .wrapper-background {
    position: absolute;
    left: auto;
    top: 0%;
    right: auto;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 300px;
    min-width: auto;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    opacity: 0.5;
  }
}

@media screen and (max-width: 479px) {
  .section-hero-img-left {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0;
  }

  .wrapper-text {
    width: 100%;
  }

  .paragraph {
    font-size: 18px;
  }
}
