.shop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-shop-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 65vh;
  margin-top: 75px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(202, 171, 171, 0.41)),
      to(rgba(202, 171, 171, 0.41))
    ),
    url("../../assets/images/shop.jpg");
  background-image: linear-gradient(
      180deg,
      rgba(202, 171, 171, 0.41),
      rgba(202, 171, 171, 0.41)
    ),
    url("../../assets/images/shop.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.shop-hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-heading {
  color: #fff;
}

.paragraph {
  color: #fff;
  text-align: center;
}

.section-card-shop-page {
  position: relative;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0 20px 60px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1500px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.category-collection {
  width: 100%;
}

.collection-list {
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-item-7 {
  padding: 0px;
  background-color: #fbfbfb;
}

.category-link {
  width: 100%;
  padding: 20px;
  -webkit-transition: background-color 400ms ease-in-out;
  transition: background-color 400ms ease-in-out;
}

.category-link:hover {
  background-color: rgba(204, 187, 166, 0.49);
}

.category-label {
  font-size: 20px;
  text-align: center;
}

.collection-list-shop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-collection-list-shop {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1500px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-items-shop {
  position: relative;
  height: 500px;

  &:hover {
    .wrapper-image-collection-shop {
      box-shadow: 0 2px 10px rgb(0 0 0 / 60%);
    }
  }
}

.wrapper-image-collection-shop {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 500px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  background-color: transparent;
  cursor: pointer;
}

.wrapper-text-card-shop {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-card-shop {
  width: 80%;
  margin-bottom: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: "ZCOOL XiaoWei", sans-serif;
  font-size: 22px;
  text-align: left;
}

.wrapper-button-shop {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  // width: 80%;
  padding: 15px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #888;
}

.price {
  width: auto;
  padding: 0px;
  border: 1px none #333;
  font-size: 16px;
}

.link-card-shop {
  font-size: 17px;
}

.wrapper-image-card-shop {
  width: auto;
  height: 368px;
  // background-image: url("../../assets/images/shop/item-1.jpg");
  // background-position: 50% 50%;
  // background-size: cover;
  cursor: pointer;
}

.w-pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-pagination-previous {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-previous-icon {
  margin-right: 4px;
}

.w-pagination-next {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.next-5 {
  display: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.w-pagination-next-icon {
  margin-left: 4px;
}

@media screen and (max-width: 991px) {
  .hero-shop-page {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-collection-list-shop {
    width: 100%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .hero-shop-page {
    height: 50vh;
    margin-top: 78px;
  }

  .section-card-shop-page {
    padding-top: 30px;
  }

  .collection-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .grid-collection-list-shop {
    width: 80%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  // .wrapper-image-collection-shop {
  //   position: static;
  //   display: block;
  // }
}

@media screen and (max-width: 479px) {
  .shop-heading {
    font-size: 50px;
    text-align: center;
  }

  .section-card-shop-page {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-collection-list-shop {
    width: 90%;
  }

  .wrapper-text-card-shop {
    width: 100%;
  }
}
