.section-hero-products {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.wrapper-product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  height: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.image-hero-product {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  margin-top: 0px;
}

.wrapper-text-product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  max-width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #333;
}

.wrapper-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.heading-product-page {
  margin-bottom: 20px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 700;
}

.price-product {
  color: #ccbba6;
  font-size: 24px;
  line-height: 1;
  font-weight: 400;
  text-align: left;
}

.paragraph-product {
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
}

.add-to-cart {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.w-commerce-commerceaddtocartform {
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.quantity-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #333;
}

.field-label {
  position: static;
  color: #ccbba6;
  font-weight: 400;
}

.w-commerce-commerceaddtocartquantityinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartquantityinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: inline-block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 10px;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
}

.w-commerce-commerceaddtocarterror {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffdede;
}

.add-to-cart-button {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #333;
  background-color: transparent;
  -webkit-transition: border-color 400ms ease-in-out,
    background-color 400ms ease-in-out, color 400ms ease-in-out,
    -webkit-transform 400ms ease-in-out;
  transition: border-color 400ms ease-in-out, background-color 400ms ease-in-out,
    color 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: border-color 400ms ease-in-out, background-color 400ms ease-in-out,
    transform 400ms ease-in-out, color 400ms ease-in-out;
  transition: border-color 400ms ease-in-out, background-color 400ms ease-in-out,
    transform 400ms ease-in-out, color 400ms ease-in-out,
    -webkit-transform 400ms ease-in-out;
  font-family: "Yeseva One", sans-serif;
  color: #333;
  font-size: 14px;
  text-align: center;
}

.add-to-cart-button:hover {
  border-style: none;
  background-color: #ccbba6;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.quantity {
  width: 100%;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: none;
  border-top-color: #333;
  border-radius: 0px;
  background-color: transparent;
  text-align: right;
}

.text-block-13 {
  text-align: center;
}

.error-message {
  text-align: center;
}

.heading-description-product {
  color: #ccbba6;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.paragraph-description-product {
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .section-hero-products {
    padding-top: 70px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-hero-product {
    height: auto;
  }

  .wrapper-text {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .section-hero-products {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrapper-product {
    width: auto;
    height: 50%;
  }

  .image-hero-product {
    width: 100%;
    height: auto;
  }

  .wrapper-text-product {
    width: 100%;
    padding-top: 0;
  }

  .wrapper-text {
    width: 80%;
  }

  .heading-product-page {
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .heading-product-page {
    font-size: 40px;
    text-align: left;
  }

  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}
