.wrapper-navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99;
  display: block;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav-overlay {
  position: absolute;
  left: 0%;
  top: -22%;
  right: 0%;
  bottom: auto;
  z-index: 0;
  opacity: 0;
  height: 350px;
  background: transparent;
  transform: translateY(-100%);
  transition-property: all;
  transition-duration: 300ms;
}

.rest-page-overlay {
  position: absolute;
  left: 0%;
  top: -22%;
  right: 0%;
  display: none;
  z-index: 0;
  height: 8000px;
  background: transparent;
}

.wrapper-burger-menu {
  display: block;
  overflow: hidden;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.nav-menu {
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
}

.navbar[data-collapse="none"] .nav-overlay {
  transform: translateY(0);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 2px 10px rgb(51 51 51 / 0.1);
}

.navbar[data-collapse="none"] .rest-page-overlay {
  display: block;
}

.navbar[data-collapse="all"] .nav-menu {
  visibility: hidden;
  opacity: 0;
  transition-delay: 50ms;
}

.navbar[data-collapse="none"] .nav-menu {
  visibility: visible;
  opacity: 1;
  transition-delay: 400ms;
}

.navbar[data-collapse="none"] .menu-1 {
  transform: translate3d(0px, 6px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
}

.navbar[data-collapse="none"] .menu-2 {
  transform: translate3d(-103px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}

.navbar[data-collapse="none"] .menu-3 {
  transform: translate3d(0px, -6px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
}

.menu {
  width: 24px;
  height: 2px;
  margin-bottom: 4px;
  background-color: #fff;
}

.wrapper-men {
  display: block;
  padding: 40px 60px 100px;
}

.grid-menu-open {
  display: grid;
  width: 100%;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 2fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.list-menu {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.heading-menu {
  margin-top: 0;
  margin-bottom: 20px;
  background-color: transparent;
  -webkit-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
  color: #fff;
  cursor: pointer;
}

.heading-menu:hover {
  color: #ccbba6;
}

.wrapper-link-menu {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
}

.link-menu {
  -webkit-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
  color: #fff !important;
  font-size: 15px;
  font-weight: 200;
  text-align: left;
  text-decoration: none;
}

.link-menu:hover {
  color: #ccbba6 !important;
}

.black {
  .navbar {
    background-color: hsla(0, 0%, 100%, 0.95);
  }

  .navbar[data-collapse="none"] .nav-overlay {
    background-color: hsla(0, 0%, 100%, 0.95);
    box-shadow: 0 2px 10px rgb(0 0 0 / 0.1);
  }

  .link-menu {
    color: #333 !important;
  }

  .link-menu:hover {
    color: #ccbba6 !important;
  }

  .heading-menu {
    color: #333;
  }

  .heading-menu:hover {
    color: #ccbba6;
  }

  .menu {
    background-color: #000;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #333;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding-right: 10px;
    padding-left: 10px;
    background-color: #333;
  }

  .grid-menu-open {
    display: flex;
    justify-content: space-between;
  }

  .wrapper-men {
    padding: 40px 20px 100px;
  }
}
