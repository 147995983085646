.section-form-contact {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("../../assets/images/contact.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.filter {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  padding-left: 250px;
  background-color: rgba(0, 0, 0, 0.79);
}

.wrapper-form-contact {
  position: relative;
  z-index: 1;
  width: 40%;
  padding: 50px 30px 30px 30px;
}

.wrapper-text-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
}

.wrapper-address {
  width: 50%;
}

.heading-contact {
  position: static;
  color: #fff;
  font-weight: 500;
}

.text-address {
  margin-top: 0px;
  margin-bottom: 40px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.text-phone {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.text-mail {
  margin-top: 0px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.text-fax {
  margin-top: 0px;
  margin-bottom: 40px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.form-contact {
  margin: 0 0 15px;
}

.wrapper-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.wrapper-form-contact {
  position: relative;
  z-index: 1;
  width: 40%;
  padding: 30px;
}

.text-field-name {
  margin-right: 10px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  background-color: transparent;
  color: #fff;
}

.text-field-email {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  background-color: transparent;
  color: #fff;
}

.text-field-subject {
  margin-left: 0;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  background-color: transparent;
  color: #fff;
}

.textarea {
  display: block;
  min-height: 100px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  background-color: transparent;
  color: #fff;
}

.button-form-contact {
  display: inline-block;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  padding: 9px 25px 9px 24px;
  background-color: #fff;
  -webkit-transition: background-color 400ms ease-in-out;
  transition: background-color 400ms ease-in-out;
  color: #333;
}

.button-form-contact:hover {
  background-color: #ccbba6;
  color: #fff;
}

.success-message-2 {
  background-color: #ccbba6;
  color: #fff;
  font-size: 16px;
}

.validate {
  display: none;
  margin-bottom: 10px;
}

.contact-form.submitted input:invalid,
.contact-form.submitted textarea:invalid {
  border-color: #ed3c0d;
}

.text-block-16 {
  font-size: 16px;
}

.map {
  width: 40%;
  height: 50%;
}

@media screen and (max-width: 991px) {
  .section-form-contact {
    padding-top: 60px;
    padding-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrapper-form-contact {
    top: 20px;
    width: 70%;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .section-form-contact {
    height: auto;
    padding: 60px 20px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .filter {
    padding-left: 0px;
  }

  .wrapper-form-contact {
    width: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .section-form-contact {
    padding-right: 40px;
    padding-left: 40px;
  }

  .wrapper-form-contact {
    width: 100%;
  }

  .wrapper-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-field-email {
    margin-left: 0px;
  }

  .wrapper-address {
    width: 100%;
  }
}
