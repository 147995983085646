.wrapper-video-blog-post {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0%;
  bottom: auto;
}

.wrapper-image-blog-post {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-position: 0px 0px;
  background-size: cover;
}
