.section-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  height: 270px;
  max-width: 100%;
  padding-right: 65px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url("../../assets/images/backgroundaboutus2.jpg");
  background-position: 50% 0%;
  background-size: cover;
  -webkit-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, -372px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 1;
  transition: all 400ms;
}

.hero-heading {
  position: relative;
  left: 24%;
  top: -7%;
  right: 0px;
  bottom: auto;
  margin-top: 0px;
  font-size: 55px;
  line-height: 2;
  -webkit-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(315px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  opacity: 0;
  transition: all 800ms;
}

.wrapper-content {
  width: 65%;
  margin-top: 60px;
  opacity: 0;
  transition: opacity 800ms;
}

.section-hero[data-page-load="init"] .wrapper-bg {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

.section-hero[data-page-load="init"] .hero-heading {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

.section-hero[data-page-load="init"] .wrapper-content {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .wrapper-bg {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .section-hero {
    overflow: hidden;
  }

  .wrapper-bg {
    width: 100%;
    height: 170px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-heading {
    left: auto;
    right: auto;
    text-align: center;
  }

  .wrapper-content {
    width: 80%;
  }
}

@media screen and (max-width: 479px) {
  .wrapper-bg {
    width: 100%;
    height: 120px;
  }

  .hero-heading {
    margin-bottom: 0px;
    font-size: 30px;
  }

  .wrapper-content {
    width: 85%;
  }
}
