.search-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  padding-right: 15px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #fff;
}

.search-menu.nav {
  position: absolute;
  left: auto;
  top: auto;
  right: 83px;
  bottom: auto;
  width: 15%;
  height: 30px;
  border-bottom-style: none;
  font-family: "ZCOOL XiaoWei", sans-serif;
  color: #fff;
  z-index: 99;
}

.search-menu.nav:active {
  color: #fff;
}

.search-menu.nav:focus {
  color: #fff;
}

.input-search {
  margin-bottom: 0px;
  border: 1px none #000;
  background-color: transparent;
  color: #fff;
}

.input-search-black {
  margin-bottom: 0px;
  border: 1px none #000;
  background-color: transparent;
  color: #000;
}

.input-search.nav {
  margin-right: 3px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  background-color: transparent;
  color: #fff;
}

.input-search-black.nav-black {
  margin-right: 3px;
  border-bottom-style: solid;
  border-bottom-color: #000;
  background-color: transparent;
  color: #000;
}

.icon-search {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  color: #7c1322;
  cursor: pointer;
  width: 18px;
}

@media screen and (max-width: 991px) {
  .search-menu.nav {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .search-menu.nav {
    display: none;
  }
}
