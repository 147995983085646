.about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-about-page {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.grid-about {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  margin-top: 60px;
  margin-bottom: 60px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
}

.paragraph-grid {
  font-size: 17px;
  text-align: center;
}

.wrapper-br-color {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 900px;
  height: 100%;
  background-color: #f5f5f5;
}

.wrapper-text-about-page {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  height: 450px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5f5;
}

.wrapper-text-about-page._1 {
  position: relative;
  background-color: transparent;
}

.wrapper-text-about-page._2 {
  background-color: transparent;
}

.wrapper-text-about-page._3 {
  background-color: transparent;
}

.wrapper-text-about-page._4 {
  position: relative;
  background-color: transparent;
}

.wrapper-text-about-page._5 {
  background-color: transparent;
}

.wrapper-text-about-page._6 {
  background-color: transparent;
}

.wrapper-text-about-page._7 {
  position: relative;
  background-color: transparent;
}

.wrapper-text-about-page._10 {
  background-color: transparent;
}

.wrapper-image-about-page {
  width: 450px;
  height: 450px;
  background-image: url("../../assets/images/1A.jpg");
  background-position: 50% 50%;
  background-size: cover;
  margin-top: 10px;
  margin-bottom: 10px;
}

.wrapper-image-about-page._1 {
  background-image: url("../../assets/images/about/awarding1.png");
}

.wrapper-image-about-page._2 {
  background-image: url("../../assets/images/about/exhibition4.jpg");
}

.wrapper-image-about-page._3 {
  background-image: url("../../assets/images/about/awarding2.jpg");
}

.wrapper-image-about-page._4 {
  background-image: url("../../assets/images/about/exhibition3.png");
}

.wrapper-image-about-page._5 {
  background-image: url("../../assets/images/about/exhibition2.jpg");
}

.wrapper-image-about-page._6 {
  background-image: url("../../assets/images/about/exhibition1.jpg");
}

.wrapper-image-about-page._7 {
  background-image: url("../../assets/images/about/exhibition5.jpg");
}

.wrapper-image-about-page._8 {
  background-image: url("../../assets/images/8.jpg");
}

.wrapper-image-about-page._10 {
  background-image: url("../../assets/images/10.jpg");
}

.date-white {
  color: #fff;
}

.white {
  color: #fff;
}

@media screen and (max-width: 991px) {
  .grid-about {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-about {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .wrapper-br-color {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .grid-about {
    overflow: hidden;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto
      auto auto auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  }

  .wrapper-br-color {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .wrapper-image-about-page {
    width: 100%;
    max-width: 100%;
  }

  .wrapper-text-about-page {
    width: 100%;
    max-width: 100%;
  }
}
